<template>
  <b-card>
    <div class="d-flex w-100 justify-content-center">
      <msi-spinner v-if="!history" />
      <div class="d-flex w-100 flex-column align-items-center" v-if="history">
        <b-table
          :items="history"
          :fields="fields"
          :current-page="currentPage"
          show-empty
          empty-text="This module hasn't been linked."
          striped
          outlined
          responsive
          stacked="sm"
          small
          hover
          sort-icon-left
          table-variant="light"
          head-variant="light"
          per-page="6"
          fixed
          :id="`daq-link-history-${daqId}`"
        />
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          :aria-controls="`daq-link-history-${daqId}`"
          v-if="rows / perPage > 1"
          />
      </div>
    </div>
  </b-card>
</template>

<script>
import moment from 'moment';
import { BCard, BTable, BPagination } from 'bootstrap-vue';

import MsiSpinner from '../MsiSpinner.vue';

export default {
  name: 'HistoryTable',
  components: {
    MsiSpinner,
    BCard,
    BTable,
    BPagination
  },
  props: {
    daqId: {
      type: String
    },
    history: {
      type: Array
    }
  },
  data() {
    return {
      perPage: 6,
      currentPage: 1,
      fields: [
        { key: 'module.name', label: 'Module', sortable: true },
        { key: 'startTime', label: 'Start', formatter: this.formatDate, sortable: true },
        { key: 'endTime', label: 'End', formatter: this.formatDate, sortable: true }
      ]
    };
  },
  computed: {
    rows() {
      if (!this.history) return 0;
      return this.history.length;
    }
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return '';
      return moment.parseZone(dateString).format('dddd, MMMM Do YYYY, h:mm:ss a');
    }
  }
};
</script>
