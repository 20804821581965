<template>
<b-container fluid class="daq-link-container m-0 px-2 px-md-3 pt-2 pt-md-3 d-flex flex-column">
  <div class="flex-grow-1 d-flex flex-column pb-2 pb-md-3 min-height-fit">
    <b-card no-body class="m-0 p-3 flex-grow-1 d-flex flex-column min-height-fit">
      <daq-list />
    </b-card>
  </div>
</b-container>
</template>

<script>
import { BContainer, BCard } from 'bootstrap-vue';

import DaqList from '../components/SiteSettings/DaqList.vue';

export default {
  name: 'DaqLink',
  components: {
    DaqList,
    BContainer,
    BCard
  }
};
</script>

<style scoped>
.daq-link-container {
  height: calc(100vh - 105px)
}
.daq-link-card {
  max-width: 400px;
}
.daq-link-header {
  font-size: 1.8rem;
}
</style>
