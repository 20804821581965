<template>
  <div>
    <div class="d-flex daq-gateway-info p-2 mb-2" v-if="this.selectedGatewayStatus === 'SERVER DISCONNECTED'">
      <font-awesome-icon class="mt-1 mr-1" icon="info-circle" />
      <div><b>Info</b>: the server has disconnected. Any operations on the DAQ could fail.</div>
    </div>

    <fieldset class="p-2 form-col mb-3">
      <legend class="px-2">DAQ Properties</legend>
      <b-form-group label-for="daq-name-input" label="DAQ Name" >
        <b-form-input id="daq-name-input" v-model="name" trim placeholder="DAQ Name" :disabled="daqUpdateInProgress" />
      </b-form-group>

      <b-form-group label-for="daq-serial-input" label="Serial Number" >
        <b-form-input id="daq-serial-input" v-model="serialNumber" trim placeholder="Serial Number" disabled />
      </b-form-group>

      <b-form-group label-for="daq-firmware-version-input" label="Firmware Version" >
        <b-form-input id="daq-firmware-version-input" :value="daq.firmwareVersion" trim placeholder="Firmware Version" disabled />
      </b-form-group>

      <b-form-group label-for="daq-model-select" label="Model" >
        <multiselect
          v-model="model"
          :options="modelOptions"
          selectLabel=""
          deselectLabel="Remove"
          placeholder="Model"
          closeOnSelect
          :maxHeight="300"
          disabled
          class="mb-3"
          id="daq-model-select">
        </multiselect>
      </b-form-group>

      <div class="d-flex">
        <b-button variant="primary" @click="updateDaq" :disabled="!updateDaqValid" size="sm">Update DAQ</b-button>
        <div class="ml-2" v-if="daqUpdateInProgress"><msi-spinner class="no-select" :size="24" /></div>
      </div>
    </fieldset>

    <fieldset class="p-2 form-col mb-3">
      <legend class="px-2">DAQ Link</legend>
      <b-form-group
        label-for="daq-gateway-select"
        label="Linked Gateway"
        :invalid-feedback="daqLinkedGatewayMessage"
        :state="!(this.offlineState.includes(this.selectedGatewayStatus))"
        class="mb-3">
        <multiselect
          v-model="selectedGateway"
          :options="gateways"
          selectLabel=""
          deselectLabel="Remove"
          placeholder="Select a Gateway"
          track-by="uuid"
          label="name"
          :allow-empty="true"
          closeOnSelect
          :maxHeight="300"
          :disabled="daqLinkInProgress || isDaqAlreadyLinked"
          id="daq-gateway-select" />
      </b-form-group>

      <b-form-group label-for="daq-module-select" label="Linked Module">
        <better-search-multiselect
          v-model="selectedSolarModule"
          :options="groupedSolarModuleOptions"
          selectLabel=""
          deselectLabel="Remove"
          placeholder="Select a Module"
          track-by="uuid"
          label="name"
          group-label="group"
          group-values="modules"
          closeOnSelect
          :maxHeight="300"
          :disabled="daqLinkInProgress || isDaqAlreadyLinked"
          class="mb-3"
          id="daq-module-select" />
      </b-form-group>

      <div class="d-flex">
        <b-button variant="primary" @click="() => linkDaq()" :disabled="!linkDaqValid" size="sm">
          {{ isDaqAlreadyLinked ? 'Unlink' : 'Link' }}
        </b-button>
        <div class="ml-2" v-if="daqLinkInProgress"><msi-spinner class="no-select" :size="24" /></div>
      </div>

      <div class="p-2 mt-3 module-link" v-if="selectedSolarModuleLink">
        <div class="d-flex">
          <font-awesome-icon class="mt-2 mr-1" icon="exclamation-circle" style="color: #F47216" />
          <div>
            <p class="m-1">The module that you are trying to link <b>({{ selectedSolarModuleLink.module.name }})</b> is already linked to
              DAQ <b>{{ selectedSolarModuleLink.daq.name }}</b> on gateway <b>{{ selectedSolarModuleLink.gateway.name }}</b>.
            </p>

            <div class="m-0 module-link-error" v-if="this.offlineState.includes(selectedSolarModuleLink.gatewayStatus)">
              Gateway <b>{{ selectedSolarModuleLink.gateway.name }}</b> seems to be offline. Unlinking the DAQ from it will likely fail.
            </div>

            <div class="d-flex mt-2">
              <b-button variant="primary" @click="unlinkSelectedModule" size="sm" :disabled="moduleUnlinkInProgress">Unlink</b-button>
              <div class="ml-2" v-if="moduleUnlinkInProgress"><msi-spinner class="no-select" :size="24" /></div>
            </div>
          </div>
        </div>
      </div>
    </fieldset>

    <b-modal title="Force Link?" hide-footer v-model="linkFailed">
      <div>
        This will tell the gateway to try to create the link even if it can't communicate with the DAQ.
      </div>
      <div class="d-flex justify-content-end pt-2">
        <b-button variant="primary" size="sm" class="mr-2" @click="handleForceLink">Force Link</b-button>
        <b-button variant="secondary" size="sm" @click="cancelForceLink">Cancel</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import Multiselect from 'vue-multiselect';
import { get } from 'vuex-pathify';
import { BFormGroup, BFormInput, BButton, BModal } from 'bootstrap-vue';

import BetterSearchMultiselect from '../BetterSearchMultiselect.vue';
import MsiSpinner from '../MsiSpinner.vue';

export default {
  name: 'EditDaq',
  components: {
    Multiselect,
    MsiSpinner,
    BetterSearchMultiselect,
    BFormGroup,
    BFormInput,
    BButton,
    BModal
  },
  props: {
    daq: {
      type: Object,
    },
    solarModules: {
      type: Array,
    },
    gateways: {
      type: Array
    },
    links: {
      type: Array,
    },
    modalId: {
      type: String,
    }
  },
  data() {
    const { link } = this.daq;
    return {
      name: this.daq.name,
      serialNumber: this.daq.serialNumber,
      model: this.daq.model || '4.0',
      modelOptions: ['4.0', '4B', '4C'],
      daqUpdateInProgress: false,
      selectedGateway: link && this.gateways.find(gateway => gateway.uuid === link.gateway.uuid),
      selectedSolarModule: link && this.solarModules.find(solarModule => solarModule.uuid === link.module.uuid),
      daqLinkInProgress: false,
      moduleUnlinkInProgress: false,
      offlineState: ['OFFLINE', 'PENDING'],
      linkFailed: false
    };
  },
  computed: {
    selectedSite: get('sites/selectedSite'),
    gatewayStatus: get('gateways/getGatewayStatus'),
    getSiteGroupedModules: get('groups/getSiteGroupedModules'),
    groupedSolarModuleOptions() {
      if (!this.selectedSite) return [];
      return this.getSiteGroupedModules(this.selectedSite.id).map(({ group, modules }) => ({ group: group.name, modules }));
    },
    daqPropertiesChanged() {
      if (this.daq.name !== this.name) return true;
      return false;
    },
    updateDaqValid() {
      return !this.daqUpdateInProgress && this.daqPropertiesChanged;
    },
    isDaqAlreadyLinked() {
      if (this.daq.link) return true;
      return false;
    },
    selectedGatewayStatus() {
      if (!this.selectedGateway) return null;
      return this.gatewayStatus(this.selectedGateway.code);
    },
    daqLinkedGatewayMessage() {
      if (this.offlineState.includes(this.selectedGatewayStatus) && this.isDaqAlreadyLinked) {
        return 'The gateway seems to be offline. Unlinking the DAQ from it will likely fail.';
      }

      if (this.offlineState.includes(this.selectedGatewayStatus) && !this.isDaqAlreadyLinked) {
        return 'The selected gateway seems to be offline. Linking the DAQ and module to it will likely fail.';
      }

      return null;
    },
    daqLinkChanged() {
      if (!this.isDaqAlreadyLinked && this.selectedGateway) return true;
      if (!this.isDaqAlreadyLinked && this.selectedSolarModule) return true;
      if (this.isDaqAlreadyLinked) return true;
      return false;
    },
    selectedSolarModuleLink() {
      if (this.isDaqAlreadyLinked || !this.selectedSolarModule) return null;
      const link = this.links.find(l => l.module.uuid === this.selectedSolarModule.uuid);
      if (link) return { ...link, gatewayStatus: this.gatewayStatus(link.gateway.code) };
      return null;
    },
    linkDaqValid() {
      const valid = this.isDaqAlreadyLinked ? true : (this.selectedGateway && this.selectedSolarModule);
      if (!this.daqLinkInProgress && this.daqLinkChanged && valid && !this.selectedSolarModuleLink) return true;
      return false;
    }
  },
  methods: {
    async updateDaq() {
      if (!this.selectedSite || !this.updateDaqValid) return;
      const { uuid: daqUuid } = this.daq;

      try {
        this.daqUpdateInProgress = true;
        const response = await this.$daqApi.put(`/sites/${this.selectedSite.id}/daqs/${daqUuid}/rename`, { query: { name: this.name } });
        this.$toastSuccess('Successful', response.message);
        this.$gtagPlugin.events.DAQ.edit({ event_label: daqUuid });
        await this.$listeners.reload();
      } catch (e) {
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        this.daqUpdateInProgress = false;
      }
    },
    async linkDaq(force = false) {
      if (!this.selectedSite || !this.linkDaqValid) return;
      const { uuid: daqUuid } = this.daq;

      try {
        this.daqLinkInProgress = true;
        if (!this.isDaqAlreadyLinked && this.selectedGateway && this.selectedSolarModule) {
          const { uuid: moduleUuid } = this.selectedSolarModule;
          const response = await this.$daqApi.post(`/sites/${this.selectedSite.id}/gateways/${this.selectedGateway.uuid}/link-module`, {
            body: { daqUuid, moduleUuid, force }
          });

          this.$toastSuccess('Successful', response.message);
          this.$gtagPlugin.events.DAQ.link({ event_label: { daqId: daqUuid, moduleId: moduleUuid } });
          await this.$listeners.reload();
        } else if (this.isDaqAlreadyLinked && this.selectedGateway && this.selectedSolarModule) {
          const { uuid: moduleUuid } = this.selectedSolarModule;
          const response = await this.$daqApi.post(`/sites/${this.selectedSite.id}/gateways/${this.selectedGateway.uuid}/unlink-module`, {
            body: { moduleUuid }
          });

          this.$toastSuccess('Successful', response.message);
          this.$gtagPlugin.events.DAQ.unlink({ event_label: { daqId: daqUuid, moduleId: moduleUuid } });
          await this.$listeners.reload();
        }
      } catch (e) {
        if (e.name === 'ApiError') {
          this.$toastError(`Error ${e.status || ''}`, e.message);
          if (e.status === 503 && !this.isDaqAlreadyLinked) this.linkFailed = true;
        } else {
          throw e;
        }
      } finally {
        this.daqLinkInProgress = false;
      }
    },
    async unlinkSelectedModule() {
      if (!this.selectedSite || this.moduleUnlinkInProgress || !this.selectedSolarModuleLink) return;

      try {
        this.moduleUnlinkInProgress = true;
        const { gateway, module, daq } = this.selectedSolarModuleLink;
        const { uuid: moduleUuid } = module;
        const { uuid: daqUuid } = daq;
        const response = await this.$daqApi.post(`/sites/${this.selectedSite.id}/gateways/${gateway.uuid}/unlink-module`, {
          body: { moduleUuid }
        });

        this.$toastSuccess('Successful', response.message);
        this.$gtagPlugin.events.DAQ.unlink({ event_label: { daqId: daqUuid, moduleId: moduleUuid } });
        await this.$listeners.reload();
      } catch (e) {
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        this.moduleUnlinkInProgress = false;
      }
    },
    handleForceLink() {
      this.linkFailed = false;
      this.linkDaq(true);
    },
    cancelForceLink() {
      this.linkFailed = false;
    }
  },
  watch: {
    daq(curDaq, prevDaq) {
      if (!curDaq || (prevDaq && prevDaq.uuid === curDaq.uuid)) return;
      const { link } = this.daq;
      this.name = this.daq.name;
      this.serialNumber = this.daq.serialNumber;
      this.model = this.daq.model || '4.0';
      this.selectedGateway = link && this.gateways.find(gateway => gateway.uuid === link.gateway.uuid);
      this.selectedSolarModule = link && this.solarModules.find(solarModule => solarModule.uuid === link.module.uuid);
    }
  }
};
</script>

<style lang="scss" scoped >
.button-icon {
  font-size: 0.9em;
  color: #6d6d6d;
  cursor: pointer;
  transition: all .1s ease-in-out;
}

.button-icon:hover {
  transform: scale(1.05);
}

.button-icon:active {
  transform: scale(0.9);
}

fieldset.form-col {
  border: thin solid rgb(233, 236, 239);
  border-radius: 5px;
}

fieldset.form-col legend {
  font-size: 0.95em;
  padding-right: 1px;
  padding-left: 1px;
  width: auto;
  margin: 0;
  color: rgb(195, 195, 195);
}

.daq-link-info {
  font-size: 0.85rem;
}

.module-link {
  background: $msi-gray-100;
}

.module-link-error {
  color: $msi-danger;
  font-size: 0.75rem;
}

.daq-gateway-info {
  color: rgb(105, 105, 105);
  font-size: 0.8rem;
  background-color: #f5f5f5;
}
</style>
