<template>
  <div>
    <b-row align-h="between" class="mx-3">
      <h1 class="display-4 daq-link-header">DAQs</h1>
    </b-row>
    <b-row class="my-3 m-0" no-gutters align-h="end" align-v="center">
      <b-col lg="6" class="d-flex my-1">
        <b-input-group size="sm">
          <b-form-input
            aria-label="search"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          ></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <div class="d-flex flex-column justify-content-between flex-grow-1">
      <b-table
        id="daqs-table"
        :items="daqs"
        :fields="tableFields"
        primaryKey="uuid"
        :show-empty="!requestInProgress && !loadingSites"
        :busy="requestInProgress || loadingSites"
        emptyText="No DAQs available"
        :per-page="perPage"
        :current-page="currentPage"
        hover
        striped
        sort-icon-left
        stacked="sm"
        responsive
        @filtered="onFiltered"
        :filter="filter"
        :filter-function="filterMethod"
      >
        <template v-slot:cell(gatewayName)="data">
          <div class="d-flex" v-if="data.item.link">
            {{ data.item.link.gateway.name }}
            <div v-if="data.item.gatewayStatus === 'OFFLINE'" class="pl-2">
              <div v-b-tooltip.hover.right title="The DAQ's linked gateway seems to be offline. Updating or unlinking the DAQ could fail.">
                <font-awesome-icon icon="exclamation-circle" class="error-icon" />
              </div>
            </div>
            <div v-if="data.item.gatewayStatus === 'SERVER DISCONNECTED'" class="pl-2">
              <div v-b-tooltip.hover.right title="The server is disconnected">
                <font-awesome-icon icon="info-circle" style="color:grey;" />
              </div>
            </div>
          </div>
          <div v-else></div>
        </template>

        <template v-slot:cell(link)="data">
          {{ data.item.link ? data.item.link.module.name : '' }}
        </template>

        <template v-slot:cell(configure)="{ item, toggleDetails }">
          <div class="d-inline-flex justify-content-end w-100 pr-0 pr-sm-1 pr-md-2 pr-lg-3">
            <b-button aria-label="configure daq" :disabled="requestInProgress" size="sm" class="mr-2" variant="primary"
            @click="() => handleEditClick(item)">
              <font-awesome-icon icon='wrench' />
            </b-button>
            <b-button aria-label="show daq history" :disabled="requestInProgress" size="sm" variant="info"
            @click="handleShowHistory(item.uuid, toggleDetails)">
              <font-awesome-icon icon='history' />
            </b-button>
          </div>
        </template>

        <template v-slot:row-details="{ item }">
          <link-history :daqId="item.uuid" :history="daqHistories[item.uuid]" />
        </template>
      </b-table>
      <div class="w-100 d-flex justify-content-center">
        <msi-spinner class="no-select" :size="60" v-if="requestInProgress || loadingSites"/>
      </div>
      <div class="d-flex justify-content-center mt-2 w-100">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          :limit="$feature.mobile ? 5 : 8"
          aria-controls="daqs-table"
          class="m-0"
        ></b-pagination>
      </div>
    </div>

    <b-modal id="daq-config-modal" title="DAQ Configuration" hide-footer>
      <edit-daq
        modalId="daq-config-modal"
        :daq="editingDaq"
        :solarModules="solarModules"
        :gateways="gateways"
        :links="links"
        @reload="handleReload"
        slot="default"/>
    </b-modal>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import { BRow, BCol, BInputGroup, BFormInput, BInputGroupAppend, BButton, BTable, BPagination, BModal } from 'bootstrap-vue';

import EditDaq from './EditDaq.vue';
import MsiSpinner from '../MsiSpinner.vue';
import LinkHistory from './LinkHistory.vue';

export default {
  name: 'DaqLink',
  components: {
    EditDaq,
    MsiSpinner,
    LinkHistory,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BTable,
    BPagination,
    BModal
  },
  data() {
    return {
      editingDaqUuid: null,
      daqHistories: {},
      requestInProgress: false,
      tableFields: [
        { key: 'name', sortable: true },
        { key: 'serialNumber', sortable: true },
        { key: 'firmwareVersion', sortable: true },
        {
          key: 'gatewayName',
          label: 'Gateway',
          sortable: true,
          formatter: (value, key, item) => (item.link ? item.link.gateway.name : ''),
          sortByFormatted: true
        },
        { key: 'link', label: 'Linked Module' },
        { key: 'configure', label: '' }
      ],
      perPage: 12,
      currentPage: 1,
      totalRows: 1,
      filter: '',
      initialized: false,
      daqFirmwareVersion: {}
    };
  },
  computed: {
    selectedSite: get('sites/selectedSite'),
    getSiteSolarModules: get('solarmodules/getSiteSolarModules'),
    getSiteDaqs: get('daqs/getSiteDaqs'),
    getSiteGateways: get('gateways/getSiteGateways'),
    getSiteLinks: get('daqLinks/getSiteLinks'),
    gatewayStatus: get('gateways/getGatewayStatus'),
    loadingSites: get('sites/loadingSites'),
    solarModules() {
      if (!this.selectedSite) return [];
      return this.getSiteSolarModules(this.selectedSite.id);
    },
    gateways() {
      if (!this.selectedSite) return [];
      return this.getSiteGateways(this.selectedSite.id);
    },
    links() {
      if (!this.selectedSite) return [];
      return this.getSiteLinks(this.selectedSite.id);
    },
    daqs() {
      if (!this.selectedSite) return [];
      const daqs = this.getSiteDaqs(this.selectedSite.id);
      return daqs.map((daq) => {
        let gateway;
        let gatewayStatus;
        const link = this.links.find(l => l.daq.uuid === daq.uuid);
        if (link) gateway = this.gateways.find(g => g.uuid === link.gateway.uuid);
        if (gateway) gatewayStatus = this.gatewayStatus(gateway.code);
        return { ...daq, link, gatewayStatus, firmwareVersion: this.daqFirmwareVersion[daq.uuid] || '-' };
      });
    },
    editingDaq() {
      if (!this.editingDaqUuid) return null;
      return this.daqs.find(daq => daq.uuid === this.editingDaqUuid);
    }
  },
  methods: {
    handleEditClick(daq) {
      this.editingDaqUuid = daq.uuid;
      this.$bvModal.show('daq-config-modal');
    },
    async handleReload() {
      this.requestInProgress = true;
      try {
        if (this.editingDaqUuid) this.$delete(this.daqHistories, this.editingDaqUuid);
        const daqPromise = this.$store.set('daqs/daqs');
        const linkPromise = this.$store.set('daqLinks/links');
        await Promise.all([daqPromise, linkPromise]);
      } catch (e) {
        this.$toastError();
      } finally {
        this.requestInProgress = false;
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    filterMethod(daq, filterString) {
      const parts = filterString.split(/ +/g).map(s => s.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'));
      const regex = RegExp(parts.join('.*'), 'i');
      if (daq.name.match(regex)) return true;
      if (daq.serialNumber.match(regex)) return true;
      if (daq.firmwareVersion.match(regex)) return true;
      if (daq.link && daq.link.gateway.name.match(regex)) return true;
      if (daq.link && daq.link.module.name.match(regex)) return true;
      return false;
    },
    handleShowHistory(uuid, toggleDetails) {
      this.getDaqHistory(uuid);
      toggleDetails();
    },
    async getDaqHistory(uuid) {
      if (!this.selectedSite.id || this.daqHistories[uuid]) return;

      try {
        const history = await this.$daqApi.get(`/sites/${this.selectedSite.id}/links/daq-history`, { query: { daq: uuid } });
        this.$set(this.daqHistories, uuid, history);
      } catch (e) {
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      }
    }
  },
  watch: {
    selectedSite: {
      immediate: true,
      handler() {
        this.daqHistories = {};
        this.initialized = false;
        this.daqFirmwareVersion = {};
      }
    },
    gateways: {
      immediate: true,
      async handler(gateways) {
        if (gateways.length && !this.initialized) {
          this.initialized = true;
          gateways.forEach(async (gateway) => {
            try {
              const gatewayClient = await this.$broker.getGatewayClient(gateway);
              const { daqs } = await gatewayClient.daqs.list({});
              daqs.forEach(daq => this.$set(this.daqFirmwareVersion, daq.uuid, daq.firmwareVersion));
            } catch (e) {
              // ignore error
            }
          });
        }
      }
    },
    daqs: {
      immediate: true,
      handler(daqs) {
        this.totalRows = daqs.length;
      }
    }
  }
};
</script>

<style scoped>
.daq-link-header {
  font-size: 1.8rem;
}

.link-in-progress {
  color: rgb(151, 154, 156);
}

.error-icon {
  color: red;
  cursor: pointer;
}
</style>
